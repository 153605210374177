// src/App.tsx
import React, { useEffect, useState } from "react";
import ChatWindow from "./components/ChatWindow";
import ChatInput from "./components/ChatInput";
import { uploadFiles } from "./services/apiService";
import WebSocketClient from "./components/WebSocketClient";
import { WEB_SOCKET_URL } from "./config/apiConfig";
import { useRecoilValue } from "recoil";
import { jwtTokenState } from "./recoil/atoms";

const App = () => {
  const [params, setParams] = useState<string | null>(null);
  const [webSocketClient, setWebSocketClient] = useState<WebSocketClient | null>(null);
  const [isAssistantSpeaking, setIsAssistantSpeaking] = useState(false);
  const [externalInput, setExternalInput] = useState<string | null>("");
  const sendExternalMessage = (message: string) => {
    setExternalInput(message);
  };
  const jwtToken = useRecoilValue(jwtTokenState);
  useEffect(() => {
    const onMessage = (data: string) => {
      const parsedData = JSON.parse(data);
      if (parsedData.finished) {
        setIsAssistantSpeaking(false);
        return;
      }
      
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        if (newMessages.length > 0) {
          if (newMessages[newMessages.length - 1].isUserMessage) {
            newMessages.push({
              message: parsedData.message,
              isUserMessage: false,
            });
          } else {
            const lastIndex = newMessages.length - 1;
            const lastMessage = newMessages[lastIndex];
            newMessages[lastIndex] = {
              ...lastMessage,
              message: lastMessage.message + parsedData.message,
            };
          }
        } else {
          newMessages.push({
            message: parsedData.message,
            isUserMessage: false,
          });
        }
        
        return newMessages;
      });
    };
    
    const client = new WebSocketClient(WEB_SOCKET_URL, onMessage);
    setWebSocketClient(client);
  }, []);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setParams(urlParams.get("params"));
  }, []);

  useEffect(() => {
    const setVhVariable = () => {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };

    setVhVariable();
    window.addEventListener("resize", setVhVariable);

    return () => {
      window.removeEventListener("resize", setVhVariable);
    };
  }, []);

  const [messages, setMessages] = useState<Array<{ message: string; isUserMessage: boolean }>>([{ message: "Hi👋", isUserMessage: false }]);

  const handleSendMessage = (message: string, isUserMessage = false) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { message, isUserMessage },
    ]);
  };

  const handleUploadFiles = async (files: FileList) => {
    const data = new FormData();
    if (!jwtToken) {
      throw new Error("App.tsx, handleUploadFiles, JWT token is not available");
    }
    data.append("jwt_token", jwtToken);
    for (let i = 0; i < files.length; i++) {
      data.append("files", files[i]);
    }
  
    try {
      const response = await uploadFiles(data);
      let message = response.data.message;
      let transcribe_audio_to_text = response.data.transcribe_audio_to_text;
      if (transcribe_audio_to_text) {
        console.log(transcribe_audio_to_text);
        sendExternalMessage(transcribe_audio_to_text);
      } else {
        setMessages((prevMessages) => [
          ...prevMessages,
          { message, isUserMessage: false },
        ]);
      }
    } catch (error) {
      const retry = window.confirm("Error uploading files. Do you want to retry?");
      if (retry) {
        handleUploadFiles(files);
      }
    }
  };

  return (
    <div
      style={{
        height: "calc(var(--vh, 1vh) * 100)",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <ChatWindow messages={messages} />
      <ChatInput
        onSendMessage={handleSendMessage}
        onUploadFiles={handleUploadFiles}
        messages={messages}
        setMessages={setMessages}
        params={params}
        webSocketClient={webSocketClient}
        isAssistantSpeaking={isAssistantSpeaking}
        setIsAssistantSpeaking={setIsAssistantSpeaking}
        externalInput={externalInput}
      />
    </div>
  );
};

export default App;
