import axios from 'axios';

export const beginClientPost = async (message: string): Promise<{ clientPosts: any[], messageWithoutClientPosts: string }> => {
  const clientPostRegex = /@begin\s*client_post\("([^"]+)"\)\s*({(?:.|\s)*?})\s*@end/g;
  const clientPosts: any[] = [];
  let match;
  while ((match = clientPostRegex.exec(message)) != null) {
    const url = match[1];
    const data = JSON.parse(match[2]);
    try {
      const response = await axios.post(url, data);
      clientPosts.push(response.data);
    } catch (error) {
      console.error(`Failed to post data to ${url}`, error);
    }
  }
  const messageWithoutClientPosts = message.replace(clientPostRegex, '');
  return { clientPosts, messageWithoutClientPosts };
};
