import React, { useState, FormEvent, useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { login } from "../services/apiService";
import { useRecoilState } from "recoil";
import { jwtTokenState, defaultBotrunState } from "../recoil/atoms";
import { v4 as uuidv4 } from "uuid";

import { useLocation, useNavigate } from 'react-router-dom'; // 如果你正在使用 react-router
import { sha256 } from 'js-sha256';

interface LoginProps {
  onLogin: () => void;
}

const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    color: '#FFFFFF',
  },
});

const LoginWp: React.FC<LoginProps> = ({ onLogin }) => {
  const [jwtToken, setJwtToken] = useRecoilState(jwtTokenState);
  const [default_botrun, setDefaultBotrun] = useRecoilState(defaultBotrunState);
  const [error, setError] = useState("");
  const classes = useStyles();

  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const user = queryParams.get('user') || ''; // 獲取 'user' 參數的值
  const tokenFromWordpress = queryParams.get('btoken'); // 獲取 'btoken' 參數的值
  const timestamp = queryParams.get('t') || '0'; // 獲取 't' 參數的值，預設為 0

  const secret = 'secret_string_1TV29mRQ49EBa0KW1DF9BJbKCYyuS9lA_'; // 與前端相同的秘密字串
  //console.log("============================================= LoginWP1");

  useEffect(() => {
    //console.log("============================================= LoginW:useEffect");
    // 驗證條件邏輯
    const validateConditions = () => {
      const userEmailFromWordpress = user || "";
      const generatedToken = sha256(`${user}${timestamp}${secret}`).toString();
      const currentTime = Math.floor(Date.now() / 1000);
      const timestampNum = parseInt(timestamp, 10); // 將 timestamp 轉換為數字
      const timeDifference = Math.abs(currentTime - timestampNum); // 計算絕對值差異


      //console.log("============================================= user + timestamp + secret");
      //console.log(user + timestamp + secret);

      //console.log("============================================= tokenFromWordpress");
      //console.log(tokenFromWordpress);
      //console.log("============================================= generatedToken");
      //console.log(generatedToken);

      //console.log("============================================= currentTime");
      //console.log(currentTime);
      //console.log("============================================= timestamp");
      //console.log(timestamp);

      //console.log("============================================= timeDifference");
      //console.log(timeDifference);

      // 檢查 token 是否匹配且時間戳在過去 60 min內 (24hr=86400, 1hr=3600, 10min=600, 1min=60)
      return (generatedToken === tokenFromWordpress && timeDifference < 3600 && timeDifference >= 0);
    };

    // 自動登入
    const autoLogin = async () => {
      const hardcodedUsername = user;
      const hardcodedPassword = "ilovebotrun";

      try {
        const data = await login(hardcodedUsername, hardcodedPassword, default_botrun);
        if (data.is_success) {
          setJwtToken(data.access_token);
          localStorage.setItem('jwtToken', data.access_token);
          onLogin();
        } else {
          setError("Username or password is incorrect.");
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (validateConditions()) {
      autoLogin();
    }

    //console.log("============================================= LoginWP2");
    navigate("/");

  }, [user, tokenFromWordpress, timestamp]);

  return (
    <div className={classes.root}>
      <h2>很抱歉，您沒有使用此服務的權限。</h2>
    </div>
  );
};

export default LoginWp;
