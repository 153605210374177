// ./services/apiServices.ts
import axios from "axios";
import { API_BASE_URL } from "../config/apiConfig";

export const uploadFiles = async (data: FormData) => {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/api/botrun/upload_files`,
      data
    );
    console.log("uploadFiles, response:")
    console.log(response)
    return response;
  } catch (error) {
    throw error;
  }
};

export async function login(username: string, password: string, default_botrun:string = "index") {
  const params = new URLSearchParams();
  params.append("username", username);
  params.append("password", password);

  const response = await axios.post(
    `${API_BASE_URL}/api/botrun/login_for_access_token?default_botrun=${default_botrun}`,
    params,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  console.log("apiService.ts, login, response.data:", response.data);
  return response.data;
}

export async function tokenVerify(botrunToken: string) {
  const params = new URLSearchParams();
  params.append("access_token", botrunToken);

  const response = await axios.post(
    `${API_BASE_URL}/api/botrun/token_verify`,
    params,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    }
  );
  // console.log("apiService.ts, tokenVerify, response.data:", response.data);
  return response.data;
}
