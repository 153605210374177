import React, { useEffect, useState, useRef } from "react";
import { beginImg } from "./BeginImg";
import { beginLink } from "./BeginLink";
import { beginClientGet } from "./BeginClientGet";
import { beginClientPost } from "./BeginClientPost";

interface ChatMessageProps {
  message: string;
  isUserMessage: boolean;
}

// 定義部分的類型
interface Part {
  type: 'text' | 'html';
  content: string;
}

const ChatMessage: React.FC<ChatMessageProps> = ({
  message,
  isUserMessage,
}) => {
  const beginEndRegex = /@begin[\s\S]*?@end/g;

  const [clientGetResponses, setClientGetResponses] = useState<string[]>([]);
  const [clientPostResponses, setClientPostResponses] = useState<string[]>([]);
  const { imageUrls, messageWithoutImages } = isUserMessage
    ? { imageUrls: [], messageWithoutImages: message }
    : beginImg(message);
  const { links, messageWithoutLinks } = isUserMessage
    ? { links: [], messageWithoutLinks: messageWithoutImages }
    : beginLink(messageWithoutImages);
  const prevClientGets = useRef<any[]>([]);
  const prevClientPosts = useRef<any[]>([]);
  const [formattedMessageParts, setFormattedMessageParts] = useState<Part[]>([]); //顯示複雜格式的內文有 @begin link, @begin img 使用的暫存變數。

  useEffect(() => {
    if (
      message.includes("@begin") &&
      message.includes("@end") &&
      !isUserMessage
    ) {
      const fetchData = async () => {
        const { clientGets } = await beginClientGet(messageWithoutLinks);
        if (
          JSON.stringify(clientGets) !== JSON.stringify(prevClientGets.current)
        ) {
          const clientGetResponses = await Promise.all(
            clientGets.map(async (url) => {
              const response = await fetch(url);
              return await response.text();
            })
          );
          setClientGetResponses(clientGetResponses);
          prevClientGets.current = clientGets;
        }
        const { clientPosts } = await beginClientPost(messageWithoutLinks);
        if (
          JSON.stringify(clientPosts) !==
          JSON.stringify(prevClientPosts.current)
        ) {
          const clientPostResponses = await Promise.all(
            clientPosts.map(async (url) => {
              const response = await fetch(url, { method: "POST" });
              return await response.text();
            })
          );
          setClientPostResponses(clientPostResponses);
          prevClientPosts.current = clientPosts;
        }
      };
      fetchData();
    }

    // 處理內文包含多個 @begin link 與多個 @begin img 的顯示方式
    if (
      !isUserMessage
    ) {
        //const linkRegex = /@begin\s*link\("([^"]+)"\)\s*((?:.|\s)*?)\s*@end/g;
        //const imgRegex = /@begin\s*img\("([^"]+)"\)\s*@end/g;
        //整合成一個 Regex，會解析 「@begin link() @end」 以及 「@begin img() @end」 
        const combinedRegex = /@begin\s*(link|img)\("([^"]+)"\)\s*([^@]*?)\s*@end/g; 
        
        let parts: Part[] = [];
        let lastIndex = 0;

        // 解析並替換自定義標籤
        const parseCustomTags = (regex: RegExp, text: string) => {
          text.replace(regex, (match, type, url, textContent, offset) => {
            // 添加匹配之前的文本
            if (offset > lastIndex) {
              parts.push({ type: 'text', content: text.substring(lastIndex, offset) });
            }
  
            // 根據類型添加 HTML 元素
            if (type === 'link') {
              parts.push({ type: 'html', content: ` <a href="${url}" target="_blank" rel="noopener noreferrer"
              style="color: #57a8ff">${textContent}</a> ` });
            } else if (type === 'img') {
              parts.push({ type: 'html', content: `<br/><img src="${url}" alt="" /><br/>` });
            }
  
            lastIndex = offset + match.length;
            return ''; // 必須返回一個字符串
          });

          // 添加最後一段文本
          if (lastIndex < message.length) {
            parts.push({ type: 'text', content: message.substring(lastIndex) });
          }
        };

        parseCustomTags(combinedRegex, message);
        setFormattedMessageParts(parts);
        console.log(parts);
      }

  }, [message]);

  let textWithoutSpecialContent = isUserMessage
    ? message
    : message.replace(beginEndRegex, "");
  
  const formattedMessage = (
    <pre style={{ margin: 0, lineHeight: "1.6rem", whiteSpace: "pre-wrap" }}>
      <code>{textWithoutSpecialContent}</code>
    </pre>
  );

  return (
    <div
      style={{
        padding: 8,
        display: "flex",
        justifyContent: isUserMessage ? "flex-end" : "flex-start",
      }}
    >
      <div
        style={{
          backgroundColor: isUserMessage
            ? "#4c6f50"
            : "rgba(255, 255, 255, 0.1)",
          borderRadius: 15,
          padding: "8px 12px",
          maxWidth: "70%",
          wordBreak: "break-word",
          color: isUserMessage ? "white" : "inherit",
          fontSize: "1.1rem",
        }}
      >

        <div>
          {isUserMessage ? (
              <div>{formattedMessage}</div> // 顯示原始文本
          ) : (
            formattedMessageParts.map((part, index) =>
              part.type === 'html' ? (
                <code key={index} dangerouslySetInnerHTML={{ __html: part.content }}></code>
              ) : (
                <pre key={index} style={{ margin: 0, lineHeight: '1.6rem', whiteSpace: 'pre-wrap' }}>
                  <code>{part.content}</code>
                </pre>
              )
            )
          )}
        </div>        

        {/* Render HTTP GET Responses */}
        {clientGetResponses.map((response, index) => (
          <pre key={index}>{JSON.stringify(response, null, 2)}</pre>
        ))}

        {/* Render HTTP POST Responses */}
        {clientPostResponses.map((response, index) => (
          <pre key={index} style={{ whiteSpace: "pre-wrap" }}>
            {response}
          </pre>
        ))}
      </div>
    </div>
  );
};

export default ChatMessage;
