export const beginImg = (
  message: string
): { imageUrls: string[]; messageWithoutImages: string } => {
  const imgRegex = /@begin\s*img\("([^"]+)"\)\s*@end/g;
  const imageUrls: string[] = [];
  let imgMatch;
  while ((imgMatch = imgRegex.exec(message)) != null) {
    imageUrls.push(imgMatch[1]);
  }
  const messageWithoutImages = message.replace(imgRegex, "");
  return { imageUrls, messageWithoutImages };
};
