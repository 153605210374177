// ./src/components/ChatInput.tsx
import React, { useState, useRef, useEffect } from "react";
import { IconButton, TextareaAutosize } from "@material-ui/core";
import { AddCircle, AttachFile, Mic, Send, Stop } from "@material-ui/icons";
import WebSocketClient from "./WebSocketClient";
import { useRecoilState } from "recoil";
import { jwtTokenState } from "../recoil/atoms";
import botrunUseAudioRecorder from "./BotrunUseAudioRecorder";
//import { isAllowPlaying, setIsAllowPlaying } from "./BeginSpeech";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
//import { ChangeLang } from "./BeginSpeech";
import Tooltip from '@material-ui/core/Tooltip';

interface ChatInputProps {
  onSendMessage: (message: string, isUserInput: boolean) => void;
  onUploadFiles: (files: FileList) => void;
  messages: Array<{ message: string; isUserMessage: boolean }>;
  setMessages: React.Dispatch<
    React.SetStateAction<Array<{ message: string; isUserMessage: boolean }>>
  >;
  params: string | null;
  webSocketClient: WebSocketClient | null;
  isAssistantSpeaking: boolean;
  setIsAssistantSpeaking: React.Dispatch<React.SetStateAction<boolean>>;
  externalInput: string | null;
}

const ChatInput: React.FC<ChatInputProps> = ({
  onSendMessage,
  onUploadFiles,
  messages,
  setMessages,
  params,
  webSocketClient,
  isAssistantSpeaking: isAssistantAnswering,
  setIsAssistantSpeaking,
  externalInput,
}) => {
  const [jwtToken, setJwtToken] = useRecoilState(jwtTokenState);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [inputValue, setInputValue] = useState("");
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { startRecording, stopRecording, fileData } = botrunUseAudioRecorder();
  const [isRecording, setIsRecording] = useState(false);
  const [isSwitchSetAllowPlaying, setIsSwitchSetAllowPlaying] = useState(false);
  
  useEffect(() => {}, []);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = "auto";
      textAreaRef.current.style.height =
        textAreaRef.current.scrollHeight + "px";
    }
  }, [inputValue]);

  useEffect(() => {
    if (externalInput) {
      console.log("ChatInput.tsx, useEffect, externalInput", externalInput);
      onSendMessage(externalInput, true);
      setIsAssistantSpeaking(true);
      if (webSocketClient) {
        webSocketClient.disconnect();
        webSocketClient.connect();
        webSocketClient.sendMessage({
          user_input: externalInput,
          jwt_token: jwtToken,
        });
      }
      setInputValue("");
    }
  }, [externalInput]);

  const handleReset = () => {
    // 1. Send the message "reset" to the server.
    onSendMessage("reset", true);
    if (webSocketClient) {
      webSocketClient.disconnect();
      webSocketClient.connect();
      webSocketClient.sendMessage({
        user_input: "reset",
        jwt_token: jwtToken,
      });
    }

    // 2. Clear the messages on the screen.
    setMessages([]);
  };

  const handleSend = () => {
    if (inputValue) {

      //roy added 語音切換
      //if(inputValue=="中文" || inputValue=="英文" || inputValue.toLowerCase()=="english" || inputValue.toLowerCase()=="chinese"){
      //  ChangeLang(inputValue);
      //  setInputValue("");
      //  return;
      //}

      onSendMessage(inputValue, true);
      setIsAssistantSpeaking(true);
      if (webSocketClient) {
        webSocketClient.disconnect();
        webSocketClient.connect();
        webSocketClient.sendMessage({
          user_input: inputValue,
          jwt_token: jwtToken,
        });
      }
      setInputValue("");
    }
  };

  const handleStop = () => {
    if (isAssistantAnswering) {
      setIsAssistantSpeaking(false);
      if (webSocketClient) {
        webSocketClient.disconnect();
      }
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      onUploadFiles(e.target.files);
      e.target.value = "";
    }
  };

  const handleRecord = () => {
    setIsRecording(!isRecording);
    if (isRecording) {
      stopRecording();
    } else {
      startRecording();
    }
  };

  //////////////////////// 手機因為安全機制，要先讓使用者手動啟動語音，之後才可以自動播放！
  let voices= speechSynthesis.getVoices();

  const handleSpeakClick = () => {
    const utterance = new SpeechSynthesisUtterance('開啟語音');
    console.log("Current Voices:", voices); // 檢查當前的語音列表
    const voice = voices.find(voice => voice.lang === 'zh-TW');
    if (voice) {
      utterance.voice = voice;
      speechSynthesis.speak(utterance);
      console.log("語音 go");
    } else {
      //const newLog = '找不到適合的語音。';
      console.log("找不到適合的語音");
    }
  };////////////////////////

  

   //20231114 roy added for 語音播放控制：Pause
   /*
   const handleSpeechOnOff = () => {
    handleSpeakClick();
    if(isSwitchSetAllowPlaying){
      window.speechSynthesis.cancel();
      setIsAllowPlaying(false);
      console.log("AFTER: isAllowPlaying=" + isAllowPlaying);
    }
    else {
      //window.speechSynthesis.resume();
      setIsAllowPlaying(true);
      console.log("AFTER: isAllowPlaying=" + isAllowPlaying);
    }
    // 更新狀態而不是修改局部變量
    setIsSwitchSetAllowPlaying(!isSwitchSetAllowPlaying);
  };
  */
  
  useEffect(() => {
    if (fileData) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(fileData);
      onUploadFiles(dataTransfer.files);
    }
  }, [fileData]);

  return (
    <div style={{ display: "flex", alignItems: "center", padding: 8 }}>
      <Tooltip title="重設波特人(Reset)">
      <IconButton size="medium" onClick={handleReset}>
        <AddCircle />
      </IconButton>
      </Tooltip>

    {/*
      <div style={{ display: 'flex', alignItems: 'center', gap: '1px' }}>
      <Tooltip title={isSwitchSetAllowPlaying ? "關閉語音功能(Speech Off)" : "開啟語音功能(Speech On)"}>
        <IconButton onClick={handleSpeechOnOff} aria-label="toggle sound">
            {isSwitchSetAllowPlaying ? <VolumeUpIcon /> : <VolumeOffIcon />}
        </IconButton>
      </Tooltip>
      </div>
    */}

      <Tooltip title="上傳檔案(Upload File)">
        <IconButton size="medium" onClick={() => fileInputRef.current?.click()}>
          <AttachFile />
        </IconButton>
      </Tooltip>

      <input
        ref={fileInputRef}
        type="file"
        multiple
        onChange={handleFileUpload}
        style={{ display: "none" }}
      />
      <TextareaAutosize
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter" && !e.shiftKey) {
            e.preventDefault();
            handleSend();
          }
        }}
        minRows={1}
        maxRows={8}
        placeholder="Type your message..."
        style={{
          flexGrow: 1,
          backgroundColor: "rgba(255, 255, 255, 0.1)",
          borderRadius: 15,
          paddingLeft: 15,
          paddingRight: 15,
          resize: "none",
          overflow: "auto",
          fontSize: "1.1rem",
          lineHeight: "1.5rem",
          color: "white",
          maxHeight: "8rem",
          overflowY: "auto",
        }}
        ref={textAreaRef}
      />

      <Tooltip title="語音輸入(Voice Dictation)">
      <IconButton size="medium" onClick={handleRecord}>
        {isRecording ? <Stop /> : <Mic />}
      </IconButton>
      </Tooltip>

      <Tooltip title={isAssistantAnswering ? "中斷波特人回答(Stop)" : "送出訊息(Send)"}>
        <IconButton
          size="medium"
          onClick={isAssistantAnswering ? handleStop : handleSend}
          style={{ color: isAssistantAnswering ? "red" : "inherit" }} // 根據isAssistantAnswering的狀態設定顏色
        >
          {isAssistantAnswering ? <Stop /> : <Send />}
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default ChatInput;

