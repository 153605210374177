export const beginLink = (
  message: string
): { links: { url: string; text: string }[]; messageWithoutLinks: string } => {
  const linkRegex = /@begin\s*link\("([^"]+)"\)\s*((?:.|\s)*?)\s*@end/g;
  const links: { url: string; text: string }[] = [];
  let linkMatch;
  while ((linkMatch = linkRegex.exec(message)) != null) {
    links.push({ url: linkMatch[1], text: linkMatch[2] });
  }
  const messageWithoutLinks = message.replace(linkRegex, "");
  return { links, messageWithoutLinks };
};
