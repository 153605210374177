import axios from 'axios';

export const beginClientGet = async (message: string): Promise<{ clientGets: any[], messageWithoutClientGets: string }> => {
  const clientGetRegex = /@begin\s*client_get\("([^"]+)"\)\s*({(?:.|\s)*?})\s*@end/g;
  const clientGets: any[] = [];
  let match;
  while ((match = clientGetRegex.exec(message)) != null) {
    const url = match[1];
    try {
      const response = await axios.get(url);
      clientGets.push(response.data);
      console.log("BeginClientGet.ts, response.data: ", response.data)
    } catch (error) {
      console.error(`Failed to fetch data from ${url}`, error);
    }
  }
  const messageWithoutClientGets = message.replace(clientGetRegex, '');
  return { clientGets, messageWithoutClientGets };
};
